import React, { useEffect, useState, useCallback } from "react";
import { filter } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { useQueryWithStore, useNotify, useDataProvider } from "react-admin";

import Spinner from "../components/layout/Spinner";
import { setAhuData, setSolarData, setHpData } from "../customReducers/actions";

import PageTitle from "../components/layout/PageTitle";

import PurchasedGraphs from "./purchased/PurchasedGraphs";
import ConsumedGraphs from "./consumed/ConsumedGraphs";
import ProducedGraphs from "./produced/ProducedGraphs";

import useIsMounted from "../utils/hooks/useIsMounted";
import useUserRole from "../utils/hooks/useUserRole";
import EmptyData from "../components/layout/EmptyData";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 0,
  },
  indicator: {
    backgroundColor: "#1AD689",
  },
  label: {
    textTransform: "none",
    fontSize: "1.125rem",
    color: "#282331",
    fontWeight: 400,
    height: "3.25rem",
    width: "12.1875rem",
    lineHeight: 1,
    backgroundColor: "#fff",
  },
}));

const AnalyticsContent = (props) => {
  const classes = useStyles();
  const notify = useNotify();

  const [ahuLoading, setAhuLoading] = useState(false);
  const [solarLoading, setSolarLoading] = useState(false);
  const [hpLoading, setHpLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [powerConsumption, setPowerConsumption] = useState(null);
  const [powerLoading, setPowerLoading] = useState(false);
  const [ahuForPowerGraph, setAhuForPowerGraph] = useState([]);

  const [hiddenContainers, setHiddenContainers] = useState([]);

  const isMounted = useIsMounted();

  const { isExternalUser, isReader } = useUserRole();

  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const selectedBuilding = props.building;
  const alertCount = useSelector(
    (state) => state.buildingFilter.activeAlertCount
  );
  const allTwins = useSelector((state) => state.buildingFilter.allTwins);
  const allAhuData = useSelector((state) => state.buildingFilter.ahuData);
  const allSolarData = useSelector((state) => state.buildingFilter.solarData);
  const allHpData = useSelector((state) => state.buildingFilter.hpData);

  // Get all ahu, solar and HP data and dispatch it to redux, unless it already exists
  useEffect(() => {
    const ahuData = [];
    let ahuMetaIds = [];
    let ahuMetaLegends = [];

    const solarData = [];
    let solarMetaIds = [];
    let solarMetaLegends = [];

    const hpData = [];
    let hpMetaIds = [];
    let hpMetaLegends = [];

    let allPromises = [];

    async function getAhuData(id) {
      let target = null;
      try {
        target = await dataProvider.getOne("blocks", { id: id });
      } catch (error) {
        notify(error.message, "warning");
      }
      if (target && target.data) {
        ahuData.push(target.data);
        if (target.data.Trends && target.data.Trends.length > 0) {
          target.data.Trends.forEach((t) => {
            if (t && t.Values && t.Values.length > 0) {
              t.Values.forEach((v) => {
                if (v.ReportingPointId) {
                  if (
                    !ahuMetaIds.includes(v.ReportingPointId) &&
                    t.TrendId === "YEARLY_HEATING_POWER_INTEGRAL"
                  ) {
                    ahuMetaIds.push(v.ReportingPointId);
                    ahuMetaLegends.push({
                      id: v.ReportingPointId,
                      legend: v.Legend,
                      trendId: t.TrendId,
                    });
                  }
                }
              });
            }
          });
        }
      }
    }

    async function getSolarData(id) {
      let target = null;
      try {
        target = await dataProvider.getOne("blocks", { id: id });
      } catch (error) {
        notify(error.message, "warning");
      }
      if (target && target.data) {
        solarData.push(target.data);
        if (target.data.Trends && target.data.Trends.length > 0) {
          target.data.Trends.forEach((t) => {
            if (t && t.Values && t.Values.length > 0) {
              t.Values.forEach((v) => {
                if (v.ReportingPointId) {
                  if (!solarMetaIds.includes(v.ReportingPointId)) {
                    solarMetaIds.push(v.ReportingPointId);
                    solarMetaLegends.push({
                      id: v.ReportingPointId,
                      legend: v.Legend,
                      trendId: t.TrendId,
                    });
                  }
                }
              });
            }
          });
        }
      }
    }

    async function getHpData(id) {
      let target = null;
      try {
        target = await dataProvider.getOne("blocks", { id: id });
      } catch (error) {
        notify(error.message, "warning");
      }
      if (target && target.data) {
        hpData.push(target.data);
        if (target.data.Trends && target.data.Trends.length > 0) {
          target.data.Trends.forEach((t) => {
            if (t && t.Values && t.Values.length > 0) {
              t.Values.forEach((v) => {
                if (v.ReportingPointId) {
                  if (!hpMetaIds.includes(v.ReportingPointId)) {
                    hpMetaIds.push(v.ReportingPointId);
                    hpMetaLegends.push({
                      id: v.ReportingPointId,
                      legend: v.Legend,
                      trendId: t.TrendId,
                    });
                  }
                }
              });
            }
          });
        }
      }
    }

    if (allTwins && !dataFetched) {
      const ahus = filter(allTwins, (t) => t.BlockType.toUpperCase() === "AHU");
      if (ahus && ahus.length > 0) {
        // Check if ahuData already exists in redux
        if (!allAhuData) {
          if (isMounted) {
            setAhuLoading(true);
          }

          const ahuPromises = ahus.map(async (a) => getAhuData(a.id));
          allPromises.push(...ahuPromises);
        }
      }
      const solars = filter(
        allTwins,
        (t) => t.BlockType.toUpperCase() === "SOLAR"
      );
      if (solars && solars.length > 0) {
        // Check if solarData already exists in redux
        if (!allSolarData) {
          if (isMounted) {
            setSolarLoading(true);
          }

          const solarPromises = solars.map(async (s) => getSolarData(s.id));
          allPromises.push(...solarPromises);
        }
      }
      const hps = filter(allTwins, (t) => t.BlockType.toUpperCase() === "HP");
      if (hps && hps.length > 0) {
        // Check if hpData already exists in redux
        if (!allHpData) {
          if (isMounted) {
            setHpLoading(true);
          }

          const hpPromises = hps.map(async (s) => getHpData(s.id));
          allPromises.push(...hpPromises);
        }
      }
    }
    if (allPromises && allPromises.length > 0 && !dataFetched) {
      Promise.all(allPromises).then(() => {
        if (isMounted) {
          setDataFetched(true);
        }

        const allMetaIds = [...ahuMetaIds, ...solarMetaIds, ...hpMetaIds];
        if (allMetaIds && allMetaIds.length > 0) {
          dataProvider
            .getList("reportdata", {
              filter: {
                buildingId: selectedBuilding.id,
                metaIds: allMetaIds,
                resolution: "month",
                v2: true,
              },
              sort: { field: "UpdateTime", order: "ASC" },
              pagination: {},
            })
            .then(({ data }) => {
              let ahuTrendData = [];
              let solarTrendData = [];
              let hpTrendData = [];
              if (
                data &&
                data.values &&
                data.values.length > 0 &&
                ahuMetaLegends &&
                ahuMetaLegends.length > 0
              ) {
                data.values.forEach((d) => {
                  ahuMetaLegends.forEach((m) => {
                    if (d.metaId === m.id) {
                      ahuTrendData.push({
                        ...d,
                        legend: m.legend,
                        trendId: m.trendId,
                      });
                    }
                  });
                });
              }
              setAhuForPowerGraph(ahuData);
              const finalAhuData = { ...ahuData, trendData: ahuTrendData };
              dispatch(setAhuData(finalAhuData));

              if (
                data &&
                data.values &&
                data.values.length > 0 &&
                solarMetaLegends &&
                solarMetaLegends.length > 0
              ) {
                data.values.forEach((d) => {
                  solarMetaLegends.forEach((m) => {
                    if (d.metaId === m.id) {
                      solarTrendData.push({
                        ...d,
                        legend: m.legend,
                        trendId: m.trendId,
                      });
                    }
                  });
                });
              }
              const finalSolarData = {
                ...solarData,
                trendData: solarTrendData,
              };
              dispatch(setSolarData(finalSolarData));
              if (
                data &&
                data.values &&
                data.values.length > 0 &&
                hpMetaLegends &&
                hpMetaLegends.length > 0
              ) {
                data.values.forEach((d) => {
                  hpMetaLegends.forEach((m) => {
                    if (d.metaId === m.id) {
                      hpTrendData.push({
                        ...d,
                        legend: m.legend,
                        trendId: m.trendId,
                      });
                    }
                  });
                });
              }
              const finalHpData = {
                ...hpData,
                trendData: hpTrendData,
              };
              dispatch(setHpData(finalHpData));
              if (isMounted) {
                setAhuLoading(false);
                setSolarLoading(false);
                setHpLoading(false);
              }
            })
            .catch((error) => {
              notify(error.message, "warning");
              if (isMounted) {
                setAhuLoading(false);
                setSolarLoading(false);
                setHpLoading(false);
              }
            });
        } else {
          if (isMounted) {
            setAhuLoading(false);
            setSolarLoading(false);
            setHpLoading(false);
          }
        }
      });
    }
  }, [
    allAhuData,
    allHpData,
    allSolarData,
    allTwins,
    dataProvider,
    dispatch,
    notify,
    selectedBuilding.id,
    dataFetched,
    isMounted,
  ]);

  // Separate useEffect for power graph
  useEffect(() => {
    setPowerLoading(true);
    dataProvider
      .getList("powerconsumptionlists", {
        filter: {
          BuildingId: selectedBuilding.id,
        },
        sort: { field: "Time", order: "ASC" },
        pagination: {},
      })
      .then(({ data }) => {
        setPowerConsumption(data);
        setPowerLoading(false);
      })
      .catch((error) => {
        setPowerLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuilding.id]);

  let buildingLoaded = null;
  let buildingError = null;

  let { loaded, error } = useQueryWithStore({
    type: "getOne",
    resource: "buildings",
    payload: {
      id: selectedBuilding.id,
      v2: true,
    },
  });
  buildingLoaded = loaded;
  buildingError = error;

  const loading = ahuLoading || solarLoading || hpLoading;

  const handleContainerHide = useCallback(
    (container) => {
      if ((isExternalUser || isReader) && !hiddenContainers.includes(container)) {
        setHiddenContainers([...hiddenContainers, container]);
      }
    },
    [hiddenContainers, isExternalUser, isReader]
  );

  const handleContainerShow = useCallback(
    (container) => {
      if ((isExternalUser || isReader) && hiddenContainers.includes(container)) {
        setHiddenContainers(hiddenContainers.filter((r) => r !== container));
      }
    },
    [hiddenContainers, isExternalUser, isReader]
  );

  if (!selectedBuilding || !buildingLoaded) {
    return (
      <div className={classes.container}>
        <PageTitle title="Analytics" activeCount={alertCount} />
        <Spinner />
      </div>
    );
  }

  if (buildingError) {
    notify(buildingError.message, "warning");
  }

  if (hiddenContainers.length >= 3) {
    return (
      <div className={classes.container}>
        <PageTitle title="Analytics" activeCount={alertCount} />
        <EmptyData />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <PageTitle title="Analytics" activeCount={alertCount} />
      <>
        {!hiddenContainers.includes("purchased") && (
          <PurchasedGraphs
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
          />
        )}
        {!hiddenContainers.includes("consumed") && (
          <ConsumedGraphs
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
            powerLoading={powerLoading}
            powerConsumption={powerConsumption}
            loading={loading}
            ahuData={ahuForPowerGraph}
          />
        )}
        {!hiddenContainers.includes("produced") && (
          <ProducedGraphs
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
            loading={loading}
          />
        )}
      </>
    </div>
  );
};

export default AnalyticsContent;
