import React, { useEffect, useState, useRef } from "react";
import Spinner from "../../components/layout/Spinner";
import { makeStyles } from "@material-ui/core";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import EmptyData from "../../components/layout/EmptyData";
import useConsTypeDataMapper from "../datamappers/purchased/useConsTypeDataMapper";
import useTargetDataMapper from "../datamappers/purchased/useTargetDataMapper";
import graphOptions from "../datamappers/consumed/graphOptions";
import useTemperatureDataMapper from "../datamappers/purchased/useTemperatureDataMapper";
import useTrendsDataMapper from "../datamappers/consumed/useTrendsDataMapper";
import { useSelector } from "react-redux";
import setLegendItemHover from "../datamappers/setLegendItemHover";
import checkGraphData from "../../utils/checkGraphData";

HC_exporting(Highcharts);
HC_export_data(Highcharts);

const useStyles = makeStyles((theme) => ({
  emptyContainer: {
    height: "28rem",
    display: "flex",
    alignItems: "center",
  },
}));

const ConsumedByTypeGraph = (props) => {
  const classes = useStyles();
  const chart = useRef(null);
  const [loading, setLoading] = useState(true);
  const [graphData, setGraphData] = useState(null);
  const [consumptionData, consumptionRaw] = useConsTypeDataMapper(
    props.resource,
    props.resource === "heating" && props.norm ? "ConsNorm" : "Consumption",
    props.norm ? true : false
  );

  const targetData = useTargetDataMapper(props.resource, "Consumption");
  const temperatureData = useTemperatureDataMapper({
    consumption: consumptionRaw,
    type: props.resource,
  });
  const ahuData = useSelector((state) => state.buildingFilter.ahuData);
  const hpData = useSelector((state) => state.buildingFilter.hpData);
  const [trendData] = useTrendsDataMapper(props.resource, props.type);

  useEffect(() => {
    setLoading(true);
    let name = props.resource === "heating" ? "District Heating " : "Utility ";
    name = props.resource === "heating" && props.norm ? "Total Heating " : name;
    let editedConsumption = [];

    if (consumptionData && consumptionData.length > 0) {
      consumptionData.forEach((c) => {
        editedConsumption.push({
          ...c,
          //stack: c.name,
          linkedTo: c.name.toString().substring(0, 4), //(year)
          showInLegend: false,
          //name: name + c.name,
          event: null,
        });
      });
    }

    // Create year groups
    let trends = [];
    if (trendData) {
      trends = trendData;
    }
    let options = graphOptions(
      editedConsumption,
      trends,
      ahuData,
      hpData,
      props.target ? targetData : null,
      props.temperature ? temperatureData : null,
      props.resource,
      props.type,
      true,
      props.norm
    );
    setGraphData(options);
  }, [
    consumptionData,
    targetData,
    ahuData,
    hpData,
    temperatureData,
    props.resource,
    props.target,
    props.temperature,
    props.type,
    trendData,
    props.norm,
  ]);

  // Additional useEffect to set loading to false when graphData changes
  useEffect(() => {
    if (graphData) {
      setLoading(false);
    }
  }, [graphData]);

  if (loading || props.loading) {
    return <Spinner style={{ height: "25rem" }} />;
  }
  if (checkGraphData({ data: graphData })) {
    if (props.showGraph && props.name) {
      props.showGraph(props.name);
    }
    return (
      <HighchartsReact
        ref={chart}
        highcharts={Highcharts}
        options={graphData}
        callback={setLegendItemHover}
      />
    );
  } else {
    if (props.hideGraph && props.name) {
      props.hideGraph(props.name);
    }
    return (
      <div className={classes.emptyContainer}>
        <EmptyData />
      </div>
    );
  }
};

export default ConsumedByTypeGraph;
