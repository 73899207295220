import React, { useEffect } from "react";
import {
  BooleanField,
  AutocompleteInput,
  Datagrid,
  Filter,
  List,
  ReferenceInput,
  ReferenceField,
  TextField,
  SelectInput,
  Pagination,
  useListController,
} from "react-admin";
import { get } from "lodash";
import { stateMapper } from "../customReducers/connectFunctions";
import DateFieldTz from "../utils/DateFieldTz";
import PageTitle from "../components/layout/PageTitle";
import { SourceTwin, SourceBuilding } from "../alerts/AlertShow";
import CheckFeedbackbutton from "./CheckFeedbackButton";
import UncheckFeedbackbutton from "./UncheckFeedbackButton";
import Feedbackbuttons from "./FeedbackButtons";

const BlockFeedbackFilter = ({ BuildingId, ...props }) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        label="Building"
        source="BuildingId"
        reference="buildings"
        sort={{ field: "ProjectName", order: "ASC" }}
        filter={{ showBAUI: true }}
        alwaysOn
      >
        <AutocompleteInput optionText="ProjectName" optionValue="id" />
      </ReferenceInput>
      <SelectInput
        alwaysOn
        allowEmpty
        source="checked"
        label="Checked"
        choices={[
          {
            id: "0",
            name: "No",
            value: false,
          },
          {
            id: "1",
            name: "Yes",
            value: true,
          },
        ]}
        optionValue="value"
      />
    </Filter>
  );
};

const BlockFeedbackBulkActionButtons = ({ selectedIds, data }) => {
  let allChecked = true;
  let allUnchecked = true;
  if (selectedIds && selectedIds.length > 0 && data) {
    selectedIds.forEach((i) => {
      if (data[i]) {
        if (data[i].checked) {
          allUnchecked = false;
        } else {
          allChecked = false;
        }
      } else {
        allChecked = false;
        allUnchecked = false;
      }
    });
  } else {
    allChecked = false;
    allUnchecked = false;
  }
  if (allChecked) {
    return <UncheckFeedbackbutton ids={selectedIds} />;
  } else if (allUnchecked) {
    return <CheckFeedbackbutton ids={selectedIds} />;
  } else if (selectedIds && selectedIds.length > 0) {
    return <p>Select only checked OR unchecked rows</p>;
  }
  return null;
};

const ValuesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
);

const BlockFeedbackList = ({
  BuildingId,
  projectname,
  permissions,
  ...props
}) => {
  // Remove unused props to get rid of errors
  let listProps = Object.assign({}, props);
  delete listProps.buildingTwinId;
  delete listProps.buildingSelected;
  delete listProps.dispatch;

  // List controller is used to check the data in bulk action buttons
  const listController = useListController(props);
  let data = null;
  let filterValues = null;
  let displayedFilters = null;
  let setFilters = null;
  if (listController) {
    ({ data, setFilters, filterValues, displayedFilters } = listController);
  }

  const tz = get(listProps, "building.TimeZoneIANA", null);

  // If a building is selected, add the selected building to the filters
  useEffect(() => {
    if (
      BuildingId &&
      setFilters &&
      filterValues &&
      filterValues.BuildingId !== BuildingId
    ) {
      setFilters(
        { ...filterValues, BuildingId, Check: null, Active: true },
        displayedFilters
      );
    }
  }, [BuildingId, filterValues, displayedFilters, setFilters]);

  return (
    <>
      <PageTitle title="Twin feedbacks" />
      <List
        pagination={<ValuesPagination />}
        sort={{ field: "feedbackDate", order: "DESC" }}
        filters={<BlockFeedbackFilter />}
        bulkActionButtons={<BlockFeedbackBulkActionButtons data={data} />}
        filterDefaultValues={{
          BuildingId: BuildingId,
        }}
        perPage={100}
        {...listProps}
      >
        <Datagrid>
          <SourceBuilding
            label="Building"
            buildingIdAsBuildingId
            sortable={false}
          />
          <SourceTwin
            label="Source twin"
            source="blockId"
            buildingSource="buildingId"
            sortable={false}
          />
          <ReferenceField
            label="Twin description"
            source="blockId"
            reference="blocks"
            link={false}
            sortable={false}
          >
            <TextField source="BlockDescription" />
          </ReferenceField>
          <TextField source="feedbackScore" sortable={false} />
          <TextField source="feedbackText" sortable={false} />
          <DateFieldTz
            source="feedbackDate"
            label={tz ? "Feedback date" : "Feedback date (UTC)"}
            showTime
            timeZone={tz}
          />
          <BooleanField source="checked" />
          <Feedbackbuttons />
        </Datagrid>
      </List>
    </>
  );
};

export default stateMapper(BlockFeedbackList);
