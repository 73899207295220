import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";
import {
  makeStyles,
  useMediaQuery,
  SvgIcon,
  MenuItem,
  ListItemIcon,
} from "@material-ui/core";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import DefaultIcon from "@material-ui/icons/ViewList";
import classnames from "classnames";
import { getResources } from "ra-core";
import { mapper } from "../../customReducers/connectFunctions";
import { useLocation } from "react-router-dom";

import CustomMenuItem from "./CustomMenuItem";
import CustomMenuLink from "./CustomMenuLink";
import HomeButton from "./HomeButton";
import Logo from "../../assets/images/image_leasegreen.png";
import SmallLogo from "../../assets/images/image_leasegreen_logo.png";
import useUserRole from "../../utils/hooks/useUserRole";
import SubMenu from "./SubMenu";
import BugForm from "../BugForm";
import FeedbackForm from "../FeedbackForm";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import BugReportIcon from "@material-ui/icons/BugReport";

const HomeIcon = (
  <SvgIcon>
    <path d="M12,5.69l5,4.5V18H15V12H9v6H7V10.19l5-4.5M12,3,2,12H5v8h6V14h2v6h6V12h3Z" />
  </SvgIcon>
);

const BackIcon = (
  <SvgIcon>
    <path d="M20,11v2H8l5.5,5.5-1.42,1.42L4.16,12l7.92-7.92L13.5,5.5,8,11Z" />
  </SvgIcon>
);

const AnalyticsIcon = (
  <SvgIcon>
    <path d="M16,11.78l4.24-7.33,1.73,1L16.74,14.5l-6.51-3.75L5.46,19H22v2H2V3H4V17.54L9.5,8Z" />
  </SvgIcon>
);

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    menuItem: {
      color: "#9590a0",
      fontSize: "1em",
      paddingTop: 14,
      paddingBottom: 14,
    },
    menuItemIcon: { minWidth: theme.spacing(5) },
  }),
  { name: "LGMenu" }
);

const CustomMenu = ({
  classes: classesOverride,
  className,
  dense,
  hasDashboard,
  onMenuClick,
  logout,
  ...rest
}) => {
  const [state, setState] = useState({
    menuAdmin: false,
    menuEnergy: false,
    menuDigitalTwins: false,
    menuTrends: false,
    menuLogs: false,
    menuConnections: false,
  });

  const [fbDialogOpen, setFbDialogOpen] = useState(false);
  const [bugDialogOpen, setBugDialogOpen] = useState(false);

  const classes = useStyles({ classes: classesOverride });
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources, shallowEqual);
  const allAlertCount = useSelector(
    (state) => state.buildingFilter.allActiveAlertCount
  );
  const reminderCount = useSelector(
    (state) => state.buildingFilter.reminderCount
  );
  const alertCount = useSelector(
    (state) => state.buildingFilter.activeAlertCount
  );
  const blockFeedbackCount = useSelector(
    (state) => state.buildingFilter.blockFeedbackCount
  );
  useSelector((state) => state.router.location.pathname); // used to force redraw on navigation

  const handleFbClickOpen = () => {
    setFbDialogOpen(true);
  };

  const handleFbClose = () => {
    setFbDialogOpen(false);
  };

  const handleBugClickOpen = () => {
    setBugDialogOpen(true);
  };

  const handleBugClose = () => {
    setBugDialogOpen(false);
  };

  // Sidebar is "two leveled", one main level is shown when no building is selected and one is shown when a building is selected
  const lsBuilding = localStorage.getItem("BuildingId");
  const buildingSelected =
    rest.buildingSelected || Number.isInteger(lsBuilding);

  const location = useLocation();
  const { pathname } = location;
  const curPath = pathname.split("/")[1];

  // Reset the state if buildingSelected changes
  useEffect(() => {
    setState({
      menuAdmin: false,
      menuEnergy: false,
      menuDigitalTwins: false,
      menuTrends: false,
      menuLogs: false,
      menuConnections: false,
    });
  }, [buildingSelected]);

  // On path change, open the correct submenu depending on current path
  useEffect(() => {
    if (curPath) {
      if (curPath === "guestusers") {
        setState((state) => ({ ...state, menuAdmin: true }));
      } else if (
        curPath === "analytics" ||
        curPath === "consumptionlists" ||
        curPath === "energytypelist" ||
        curPath === "powerconsumptionlists"
      ) {
        setState((state) => ({ ...state, menuEnergy: true }));
      } else if (
        curPath === "blocks" ||
        curPath === "compensationfunctions" ||
        curPath === "compensations"
      ) {
        setState((state) => ({ ...state, menuDigitalTwins: true }));
      } else if (curPath === "valuestorage" || curPath === "buildingpoints") {
        setState((state) => ({ ...state, menuTrends: true }));
      } else if (
        curPath === "operationlog" ||
        curPath === "buildingsharepoint"
      ) {
        setState((state) => ({ ...state, menuLogs: true }));
      } else if (
        curPath === "busadapters" ||
        curPath === "registerlists" ||
        curPath === "remoteconnection"
      ) {
        setState((state) => ({ ...state, menuConnections: true }));
      }
    }
  }, [curPath, buildingSelected]);

  const onHomeClick = () => {
    localStorage.setItem("BuildingId", null);
    localStorage.setItem("BuildingTwinId", null);
    rest.changebuilding();
  };

  const handleToggle = (menuName) => {
    setState((state) => ({ ...state, [menuName]: !state[menuName] }));
  };

  const { isAdmin, isExternalUser, isAdminOrUser } = useUserRole();

  // Higher level menu
  const higherLevelMenuItems = [
    <HomeButton
      key={"home"}
      to="/"
      primaryText={"Home"}
      leftIcon={HomeIcon}
      onClick={onHomeClick}
      sidebarIsOpen={open}
      exact
      noBottomBorder
    />,
  ];

  if (!isExternalUser) {
    higherLevelMenuItems.push(
      <CustomMenuLink
        key="buildings"
        to="/buildings"
        primaryText="Buildings"
        leftIcon={
          resources.find((r) => r.name === "buildings") ? (
            resources.find((r) => r.name === "buildings").icon || (
              <DefaultIcon />
            )
          ) : (
            <DefaultIcon />
          )
        }
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />,
      isAdminOrUser && (
        <CustomMenuLink
          key="energysavings"
          to="/energysavings"
          primaryText="Savings Portfolio"
          leftIcon={
            resources.find((r) => r.name === "energysavings") ? (
              resources.find((r) => r.name === "energysavings").icon || (
                <DefaultIcon />
              )
            ) : (
              <DefaultIcon />
            )
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ),
      <CustomMenuLink
        key="alerts"
        to="/alerts"
        primaryText="Alerts"
        leftIcon={
          resources.find((r) => r.name === "alerts") ? (
            resources.find((r) => r.name === "alerts").icon || <DefaultIcon />
          ) : (
            <DefaultIcon />
          )
        }
        onClick={onMenuClick}
        sidebarIsOpen={open}
        alertCount={allAlertCount}
      />,
      isAdminOrUser && (
        <CustomMenuLink
          key="reminders"
          to="/reminders"
          primaryText="Reminders"
          leftIcon={
            resources.find((r) => r.name === "reminders") ? (
              resources.find((r) => r.name === "reminders").icon || (
                <DefaultIcon />
              )
            ) : (
              <DefaultIcon />
            )
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
          alertCount={reminderCount}
        />
      ),
      isAdminOrUser && (
        <CustomMenuLink
          key="remoteconnection"
          to="/remoteconnection"
          primaryText="Remote Connections"
          leftIcon={
            resources.find((r) => r.name === "remoteconnection") ? (
              resources.find((r) => r.name === "remoteconnection").icon || (
                <DefaultIcon />
              )
            ) : (
              <DefaultIcon />
            )
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ),
      <CustomMenuLink
        checkForList
        key="busadapters"
        to="/busadapters"
        primaryText="Bus Adapters"
        leftIcon={
          resources.find((r) => r.name === "busadapters") ? (
            resources.find((r) => r.name === "busadapters").icon || (
              <DefaultIcon />
            )
          ) : (
            <DefaultIcon />
          )
        }
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />,
      isAdminOrUser && (
        <CustomMenuLink
          key="blockfeedbacks"
          to="/blockfeedbacks"
          primaryText="Twin Feedbacks"
          leftIcon={
            resources.find((r) => r.name === "blockfeedbacks") ? (
              resources.find((r) => r.name === "blockfeedbacks").icon || (
                <DefaultIcon />
              )
            ) : (
              <DefaultIcon />
            )
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
          alertCount={blockFeedbackCount}
        />
      )
    );
  }
  // The admin view for editing user settings etc
  if (isAdmin) {
    higherLevelMenuItems.push(
      <SubMenu
        key="admin"
        handleToggle={() => handleToggle("menuAdmin")}
        isOpen={state.menuAdmin}
        sidebarIsOpen={open}
        name="Admin"
        icon={<SupervisorAccountIcon />}
      >
        <CustomMenuLink
          key="guestusers"
          to="/guestusers"
          primaryText="Guest Users"
          leftIcon={<SupervisorAccountIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          subMenu
        />
      </SubMenu>
    );
  }

  const lowerLevelMenuItems = [
    <HomeButton
      key={"home"}
      to="/"
      primaryText={"Home"}
      leftIcon={BackIcon}
      onClick={onHomeClick}
      sidebarIsOpen={open}
      exact
    />,
    hasDashboard && (
      <CustomMenuItem
        key="dashboard"
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
    ),
    <SubMenu
      key="energy"
      handleToggle={() => handleToggle("menuEnergy")}
      isOpen={state.menuEnergy}
      sidebarIsOpen={open}
      name="Energy"
      icon={AnalyticsIcon}
    >
      <CustomMenuLink
        key="analytics"
        to="/analytics"
        primaryText="Analytics"
        leftIcon={AnalyticsIcon}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {isAdminOrUser && (
        <CustomMenuLink
          checkForList
          key="consumptionlists"
          to="/consumptionlists"
          primaryText="Consumption"
          leftIcon={
            resources.find((r) => r.name === "consumptionlists") ? (
              resources.find((r) => r.name === "consumptionlists").icon || (
                <DefaultIcon />
              )
            ) : (
              <DefaultIcon />
            )
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {isAdminOrUser && (
        <CustomMenuLink
          checkForList
          key="energytypelist"
          to="/energytypelist"
          primaryText="EnergyType"
          leftIcon={
            resources.find((r) => r.name === "consumptionlists") ? (
              resources.find((r) => r.name === "consumptionlists").icon || (
                <DefaultIcon />
              )
            ) : (
              <DefaultIcon />
            )
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {isAdminOrUser && (
        <CustomMenuLink
          checkForList
          key="powerconsumptionlists"
          to="/powerconsumptionlists"
          primaryText="Power"
          leftIcon={
            resources.find((r) => r.name === "powerconsumptionlists") ? (
              resources.find((r) => r.name === "powerconsumptionlists")
                .icon || <DefaultIcon />
            ) : (
              <DefaultIcon />
            )
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
    </SubMenu>,
  ];

  lowerLevelMenuItems.push(
    !isExternalUser && (
      <SubMenu
        key="digitaltwins"
        handleToggle={() => handleToggle("menuDigitalTwins")}
        isOpen={state.menuDigitalTwins}
        sidebarIsOpen={open}
        name="Digital Twins"
        icon={
          resources.find((r) => r.name === "blocks") ? (
            resources.find((r) => r.name === "blocks").icon || <DefaultIcon />
          ) : (
            <DefaultIcon />
          )
        }
      >
        <CustomMenuLink
          checkForList
          key="blocks"
          to="/blocks"
          primaryText="Digital Twins"
          leftIcon={
            resources.find((r) => r.name === "blocks") ? (
              resources.find((r) => r.name === "blocks").icon || <DefaultIcon />
            ) : (
              <DefaultIcon />
            )
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
        {isAdminOrUser && (
          <CustomMenuLink
            checkForList
            key="compensationfunctions"
            to="/compensationfunctions"
            primaryText="Functions"
            leftIcon={
              resources.find((r) => r.name === "compensationfunctions") ? (
                resources.find((r) => r.name === "compensationfunctions")
                  .icon || <DefaultIcon />
              ) : (
                <DefaultIcon />
              )
            }
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        )}
        {isAdminOrUser && (
          <CustomMenuLink
            checkForList
            key="compensations"
            to="/compensations"
            primaryText="Compensations"
            leftIcon={
              resources.find((r) => r.name === "compensations") ? (
                resources.find((r) => r.name === "compensations").icon || (
                  <DefaultIcon />
                )
              ) : (
                <DefaultIcon />
              )
            }
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        )}
      </SubMenu>
    ),
    !isExternalUser && (
      <CustomMenuLink
        checkForList
        key="alerts"
        to="/alerts"
        primaryText="Alerts"
        leftIcon={
          resources.find((r) => r.name === "alerts") ? (
            resources.find((r) => r.name === "alerts").icon || <DefaultIcon />
          ) : (
            <DefaultIcon />
          )
        }
        onClick={onMenuClick}
        sidebarIsOpen={open}
        alertCount={alertCount}
      />
    ),
    !isExternalUser && isAdminOrUser && (
      <CustomMenuLink
        key="reminders"
        to="/reminders"
        primaryText="Reminders"
        leftIcon={
          resources.find((r) => r.name === "reminders") ? (
            resources.find((r) => r.name === "reminders").icon || (
              <DefaultIcon />
            )
          ) : (
            <DefaultIcon />
          )
        }
        onClick={onMenuClick}
        sidebarIsOpen={open}
        alertCount={reminderCount}
      />
    ),
    !isExternalUser && (
      <SubMenu
        key="trends"
        handleToggle={() => handleToggle("menuTrends")}
        isOpen={state.menuTrends}
        sidebarIsOpen={open}
        name="Trends"
        icon={<AssessmentOutlinedIcon />}
      >
        <CustomMenuLink
          checkForList
          key="valuestorage"
          to="/valuestorage"
          primaryText="Valuestorage"
          leftIcon={
            resources.find((r) => r.name === "valuestorage") ? (
              resources.find((r) => r.name === "valuestorage").icon || (
                <DefaultIcon />
              )
            ) : (
              <DefaultIcon />
            )
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
        {isAdminOrUser && (
          <CustomMenuLink
            checkForList
            key="buildingpoints"
            to="/buildingpoints"
            primaryText="Buildingpoints"
            leftIcon={
              resources.find((r) => r.name === "buildingpoints") ? (
                resources.find((r) => r.name === "buildingpoints").icon || (
                  <DefaultIcon />
                )
              ) : (
                <DefaultIcon />
              )
            }
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        )}
      </SubMenu>
    ),
    isAdminOrUser && (
      <SubMenu
        key="connections"
        handleToggle={() => handleToggle("menuConnections")}
        isOpen={state.menuConnections}
        sidebarIsOpen={open}
        name="Connections"
        icon={<DefaultIcon />}
      >
        <CustomMenuLink
          checkForList
          key="busadapters"
          to="/busadapters"
          primaryText="Bus Adapters"
          leftIcon={
            resources.find((r) => r.name === "busadapters") ? (
              resources.find((r) => r.name === "busadapters").icon || (
                <DefaultIcon />
              )
            ) : (
              <DefaultIcon />
            )
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
        <CustomMenuLink
          checkForList
          key="registerlists"
          to="/registerlists"
          primaryText="Registers"
          leftIcon={
            resources.find((r) => r.name === "registerlists") ? (
              resources.find((r) => r.name === "registerlists").icon || (
                <DefaultIcon />
              )
            ) : (
              <DefaultIcon />
            )
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
        {isAdminOrUser && (
          <CustomMenuLink
            checkForList
            key="remoteconnection"
            to="/remoteconnection"
            primaryText="Remote Connections"
            leftIcon={
              resources.find((r) => r.name === "remoteconnection") ? (
                resources.find((r) => r.name === "remoteconnection").icon || (
                  <DefaultIcon />
                )
              ) : (
                <DefaultIcon />
              )
            }
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        )}
      </SubMenu>
    ),
    isAdminOrUser && (
      <CustomMenuLink
        key="blockfeedbacks"
        to="/blockfeedbacks"
        primaryText="Twin Feedbacks"
        leftIcon={
          resources.find((r) => r.name === "blockfeedbacks") ? (
            resources.find((r) => r.name === "blockfeedbacks").icon || (
              <DefaultIcon />
            )
          ) : (
            <DefaultIcon />
          )
        }
        onClick={onMenuClick}
        sidebarIsOpen={open}
        alertCount={blockFeedbackCount}
      />
    ),
    <SubMenu
      key="logs"
      handleToggle={() => handleToggle("menuLogs")}
      isOpen={state.menuLogs}
      sidebarIsOpen={open}
      name="Logs"
      icon={
        resources.find((r) => r.name === "operationlog") ? (
          resources.find((r) => r.name === "operationlog").icon || (
            <DefaultIcon />
          )
        ) : (
          <DefaultIcon />
        )
      }
    >
      <CustomMenuLink
        checkForList
        key="operationlog"
        to="/operationlog"
        primaryText="Operations"
        leftIcon={
          resources.find((r) => r.name === "operationlog") ? (
            resources.find((r) => r.name === "operationlog").icon || (
              <DefaultIcon />
            )
          ) : (
            <DefaultIcon />
          )
        }
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {!isExternalUser && (
        <CustomMenuLink
          checkForList
          key="buildingsharepoint"
          to="/buildingsharepoint"
          primaryText="Risks & Issues"
          leftIcon={
            resources.find((r) => r.name === "buildingsharepoint") ? (
              resources.find((r) => r.name === "buildingsharepoint").icon || (
                <DefaultIcon />
              )
            ) : (
              <DefaultIcon />
            )
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
    </SubMenu>
  );

  const logo = (
    <div
      style={{
        boxSizing: "border-box",
        height: 60,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        borderBottom: "1px solid #463d5c",
      }}
    >
      <img
        style={{
          objectFit: "contain",
          height: "35%",
          marginRight: open ? 45 : 0,
        }}
        alt="LeaseGreen"
        src={open ? Logo : SmallLogo}
      />
    </div>
  );

  return (
    <div className={classnames(classes.main, className)}>
      {logo}
      {!buildingSelected ? higherLevelMenuItems : lowerLevelMenuItems}
      {isMedium && (
        <>
          {!isExternalUser && (
            <MenuItem className={classes.menuItem} onClick={handleBugClickOpen}>
              <ListItemIcon className={classes.menuItemIcon}>
                <BugReportIcon />
              </ListItemIcon>
              Report a bug
            </MenuItem>
          )}
          <MenuItem className={classes.menuItem} onClick={handleFbClickOpen}>
            <ListItemIcon className={classes.menuItemIcon}>
              <ContactSupportIcon />
            </ListItemIcon>
            Send feedback
          </MenuItem>
          {!isExternalUser && (
            <BugForm open={bugDialogOpen} onClose={handleBugClose} />
          )}
          <FeedbackForm open={fbDialogOpen} onClose={handleFbClose} />
        </>
      )}
      {isXSmall && logout}
    </div>
  );
};

CustomMenu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  logout: PropTypes.element,
  onMenuClick: PropTypes.func,
};

CustomMenu.defaultProps = {
  onMenuClick: () => null,
};

export default mapper(CustomMenu);
