// 18/04/2023 Unique component to show heating energytypes by source in analytics graph
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { filter } from "lodash";

import { raConsumptionToHighchartsData } from "../../../utils/raDataToHighcharts";
//import graphColor from "../../../utils/graphColor";
import handleLegendClick from "./handleLegendClick";

const values = [
  "Oil",
  "DistrictHeating",
  "NaturalGas",
  "LiquidPetroleum",
  "Steam",
  "CondensateHeat",
];

const names = [
  "Oil",
  "District heating",
  "Natural gas",
  "LGP",
  "Steam",
  "Condensate heat",
];

const graphColors = [
  "#D7801D",
  "#B16019",
  "#8C4215",
  "#692410",
  "#470404",
  "#380303",
];

const seriesMapping = (data, type, value, allCons) => {
  // Get unique years from consumption data.
  const years = [
    ...new Set(
      data.map((obj) => {
        const date = new Date(obj.Time);
        return date.getFullYear();
      })
    ),
  ];

  const lightenHexColor = (color, percent) => {
    var num = parseInt(color.replace("#", ""), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      G = ((num >> 8) & 0x00ff) + amt,
      B = (num & 0x0000ff) + amt;

    return (
      "#" +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255)
      )
        .toString(16)
        .slice(1)
    );
  };

  const getColor = (index, jndex) => {
    if (years.length > 0) {
      return lightenHexColor(graphColors[jndex], (index * 45) / years.length);
    } else {
      return lightenHexColor(graphColors[jndex], index * 45);
    }
  };

  // create an array of series, one for each year.
  const consumptionSeries = years.map((year, index) => {
    return values.map((v, jndex) => {
      return {
        name: year + " " + names[jndex],
        stack: year,
        data: raConsumptionToHighchartsData(data, type, v, year, allCons),
        visible: true,
        color: getColor(index, jndex), //smallest year gets unmodified color, greatest year gets lightest
        events: {
          legendItemClick: (event) => {
            event.preventDefault();
            handleLegendClick(event.target);
            return false;
          },
        },
      };
    });
  });

  return consumptionSeries.flat();
};

const useConsTypeDataMapper = (type, value, allCons = false) => {
  const consumption = useSelector(
    (state) => state.buildingFilter.consumptionByType
  );
  const [consumptionSeries, setConsumptionSeries] = useState([]);

  // If !allCons, the raw consumption does not include HP data
  let consumptionRaw = allCons
    ? consumption
    : filter(consumption, (c) => {
        return (
          c.Source.toLowerCase() === "manual" ||
          c.Source.toLowerCase() === "metry"
        );
      });

  useEffect(() => {
    if (!consumption) return;
    setConsumptionSeries(seriesMapping(consumption, type, value, allCons));
  }, [consumption, type, value, allCons]);

  return [consumptionSeries, consumptionRaw];
};

export default useConsTypeDataMapper;
