export const setConsumptionUnitText = type => {
  switch (type) {
    case "electricity":
      return "Electricity (KWh)";
    case "heating":
      return "Heating (MWh)";
    case "cooling":
      return "Cooling (MWh)";
    case "water":
      return "Water (m³)";
    default:
      return "unit unknown";
  }
};

export const setPowerConsumptionUnitText = type => {
  switch (type) {
    case "Power":
      return "Power (kW)";
    case "WaterAmount":
      return "Flow rate (m³/h)";
    case "WIncoming":
      return "Supply temperature (°C)";
    case "WOutgoing":
      return "Return temperature (°C)";
    case "Cooling":
      return "Temperature difference (°C)";
    default:
      return "unit unknown";
  }
};
