import React, { useState, useEffect, useCallback } from "react";
import { useDataProvider } from "react-admin";
import { makeStyles, Tab, Tabs, Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";

import ConsumedGraph from "./ConsumedGraph";
import ConsumedByTypeGraph from "./ConsumedByTypeGraph";
import CardWithTitle from "../../components/layout/CardWithTitle";
import PowerConsumptionGraphs from "../../powerconsumptionlists/PowerConsumptionGraphs";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { addTemperaturesToConsumptionData } from "../../utils/raDataToHighcharts";
import useUserRole from "../../utils/hooks/useUserRole";

const useStyles = makeStyles(() => ({
  root: { marginTop: "2.5rem" },
  container: {
    minHeight: "28rem",
  },
  indicator: {
    backgroundColor: "#1AD689",
  },
  label: {
    textTransform: "none",
    fontSize: "1.125rem",
    color: "#282331",
    fontWeight: 400,
    height: "3.25rem",
    width: "12.1875rem",
    lineHeight: 1,
  },
}));

function TabPanel(props) {
  const { children, value, index, className, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={className} p={3}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ConsumedGraphs = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();

  const [value, setValue] = useState("heatingTotal");
  const [consTemps, setConsTemps] = useState(null);
  const [allTwinsData, setAllTwinsData] = useState([]);
  const [twinsLoading, setTwinsLoading] = useState(false);
  const [fetchDone, setFetchDone] = useState(false);
  const [hiddenGraphs, setHiddenGraphs] = useState([]);
  const { isExternalUser, isReader } = useUserRole();
  const { hideContainer, showContainer } = props;

  const temperatures = useSelector(
    (state) => state.buildingFilter.buildingTemp
  );
  const allTwins = useSelector((state) => state.buildingFilter.allTwins);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  // Set the temperatureconsumptions for power scatter graph
  // and get data for all HX & AHU twins
  useEffect(() => {
    if (
      !consTemps &&
      props.powerConsumption &&
      props.powerConsumption.length > 0 &&
      temperatures &&
      temperatures.length > 0
    ) {
      setConsTemps(
        addTemperaturesToConsumptionData(
          props.powerConsumption,
          cloneDeep(temperatures)
        )
      );
    }
  }, [
    consTemps,
    props.powerConsumption,
    temperatures,
    allTwins,
    allTwinsData,
    dataProvider,
    twinsLoading,
  ]);

  // Separate useEffect for hx data. Ahu data is gotten from parent
  useEffect(() => {
    if (
      !fetchDone &&
      !twinsLoading &&
      !allTwinsData.length > 0 &&
      allTwins &&
      allTwins.length > 0
    ) {
      setTwinsLoading(true);
      let allData = [];
      let twinPromises = allTwins.map(async (t) => {
        if (t.BlockType.toLowerCase() === "hx") {
          try {
            const twin = await dataProvider.getOne("blocks", { id: t.id });
            allData.push(twin.data);
            return twin;
          } catch (error) {}
        }
      });
      Promise.all(twinPromises).then(() => {
        setFetchDone(true);
        setTwinsLoading(false);
        setAllTwinsData(allData);
      });
    }
  }, [twinsLoading, allTwinsData, allTwins, dataProvider, fetchDone]);

  const handleGraphHide = useCallback(
    (graph) => {
      if ((isExternalUser || isReader) && !hiddenGraphs.includes(graph)) {
        setHiddenGraphs([...hiddenGraphs, graph]);
      }
    },
    [hiddenGraphs, isExternalUser, isReader]
  );

  const handleGraphShow = useCallback(
    (graph) => {
      if ((isExternalUser || isReader) && hiddenGraphs.includes(graph)) {
        setHiddenGraphs(hiddenGraphs.filter((r) => r !== graph));
      }
    },
    [hiddenGraphs, isExternalUser, isReader]
  );

  // Each time hiddenResources changes, change the selected resource if necessary
  useEffect(() => {
    const possibleGraphIds = [
      "heatingTotal",
      "heatingSource",
      "electricitySource",
      "power",
    ];
    // Set current selection as the "top" one
    for (let i = 0; i < possibleGraphIds.length; i++) {
      const p = possibleGraphIds[i];
      if (!hiddenGraphs.includes(p)) {
        setValue(p);
        break;
      }
    }
  }, [hiddenGraphs]);

  useEffect(() => {
    if (hiddenGraphs.length >= 4 && hideContainer) {
      hideContainer("consumed");
    }
    if (hiddenGraphs.length < 4 && showContainer) {
      showContainer("consumed");
    }
  }, [hiddenGraphs, hideContainer, showContainer]);

  return (
    <div className={classes.root}>
      <CardWithTitle title={"Consumed"} spacerColor="#2E2247">
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          classes={{ indicator: classes.indicator }}
          value={value}
          onChange={handleChange}
        >
          {!hiddenGraphs.includes("heatingTotal") && (
            <Tab
              classes={{ root: classes.label }}
              label="Total heating weather corrected"
              value="heatingTotal"
            />
          )}
          {!hiddenGraphs.includes("heatingBySourceType") && (
            <Tab
              classes={{ root: classes.label }}
              label="Heating by source type"
              value="heatingBySourceType"
            />
          )}
          {!hiddenGraphs.includes("heatingSource") && (
            <Tab
              classes={{ root: classes.label }}
              label="Heating by source"
              value="heatingSource"
            />
          )}
          {!isExternalUser && (
            <Tab
              classes={{ root: classes.label }}
              label="Heating by load"
              value="heatingLoad"
            />
          )}
          {!hiddenGraphs.includes("electricitySource") && (
            <Tab
              classes={{ root: classes.label }}
              label="Electricity by source"
              value="electricitySource"
            />
          )}
          {
            <Tab
              classes={{ root: classes.label }}
              label="Electricity by load"
              value="electricityLoad"
            />
          }
          {!hiddenGraphs.includes("coolingSource") && (
            <Tab
              classes={{ root: classes.label }}
              label="Cooling by source"
              value="coolingSource"
            />
          )}
          {!hiddenGraphs.includes("power") && (
            <Tab
              classes={{ root: classes.label }}
              label="Heating power"
              value="power"
            />
          )}
        </Tabs>
        <div style={{ display: "none" }}>
          <ConsumedGraph
            resource="heating"
            type="source"
            target
            temperature
            norm
            name="heatingTotal"
            showGraph={handleGraphShow}
            hideGraph={handleGraphHide}
          />
          <ConsumedByTypeGraph
            resource="heating"
            type="source"
            target
            temperature
            loading={props.loading}
            name="heatingBySourceTypes"
            showGraph={handleGraphShow}
            hideGraph={handleGraphHide}
          />
          <ConsumedGraph
            resource="electricity"
            type="source"
            target
            loading={props.loading}
            name="electricitySource"
            showGraph={handleGraphShow}
            hideGraph={handleGraphHide}
          />
          <ConsumedGraph
            resource="cooling"
            type="source"
            target
            loading={props.loading}
            name="coolingSource"
            showGraph={handleGraphShow}
            hideGraph={handleGraphHide}
          />
          <PowerConsumptionGraphs
            powerLoading={props.powerLoading}
            powerConsumption={cloneDeep(props.powerConsumption)}
            temperatures={cloneDeep(temperatures)}
            allTwins={cloneDeep(allTwins)}
            allTwinsData={[...allTwinsData, ...props.ahuData]}
            twinsLoading={twinsLoading}
            consTemps={consTemps}
            noTitle={true}
            name="power"
            showGraph={handleGraphShow}
            hideGraph={handleGraphHide}
          />
        </div>

        <TabPanel
          className={classes.container}
          value={value}
          index={"heatingTotal"}
        >
          <ConsumedGraph
            resource="heating"
            type="source"
            target
            temperature
            norm
            name="heatingTotal"
          />
        </TabPanel>
        <TabPanel
          className={classes.container}
          value={value}
          index={"heatingBySourceType"}
        >
          <ConsumedByTypeGraph
            resource="heating"
            type="source"
            target
            temperature
            loading={props.loading}
            name="heatingBySourceType"
          />
        </TabPanel>
        <TabPanel
          className={classes.container}
          value={value}
          index={"heatingSource"}
        >
          <ConsumedGraph
            resource="heating"
            type="source"
            target
            temperature
            loading={props.loading}
            name="heatingSource"
          />
        </TabPanel>
        <TabPanel
          className={classes.container}
          value={value}
          index={"heatingLoad"}
        >
          <ConsumedGraph
            resource="heating"
            type="load"
            target
            temperature
            loading={props.loading}
            name="heatingLoad"
          />
        </TabPanel>
        <TabPanel
          className={classes.container}
          value={value}
          index={"electricitySource"}
        >
          <ConsumedGraph
            resource="electricity"
            type="source"
            target
            loading={props.loading}
            name="electricitySource"
          />
        </TabPanel>
        <TabPanel
          className={classes.container}
          value={value}
          index={"electricityLoad"}
        >
          <ConsumedGraph
            resource="electricity"
            type="load"
            target
            loading={props.loading}
            name="electricityLoad"
          />
        </TabPanel>
        <TabPanel
          className={classes.container}
          value={value}
          index={"coolingSource"}
        >
          <ConsumedGraph
            resource="cooling"
            type="source"
            target
            loading={props.loading}
            name="coolingSource"
          />
        </TabPanel>
        <TabPanel className={classes.container} value={value} index={"power"}>
          <PowerConsumptionGraphs
            powerLoading={props.powerLoading}
            powerConsumption={cloneDeep(props.powerConsumption)}
            temperatures={cloneDeep(temperatures)}
            allTwins={cloneDeep(allTwins)}
            allTwinsData={[...allTwinsData, ...props.ahuData]}
            twinsLoading={twinsLoading}
            consTemps={consTemps}
            noTitle={true}
            name="power"
          />
        </TabPanel>
      </CardWithTitle>
    </div>
  );
};

export default ConsumedGraphs;
