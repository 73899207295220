import { setConsumptionUnitText } from "../../../utils/unitTextParsers";
import { sum } from "lodash";
import setVisibleYears from "../purchased/setVisibleYears";
import createYearGroups from "./createYearGroups";
//import setStackColor from "./setStackColor";
import mapHeatingByLoad from "./mapHeatingByLoad";
import mapElectricityByLoad from "./mapElectricityByLoad";

const graphOptions = (
  graphData,
  trends,
  ahuData,
  hpData,
  targetData,
  temperatureSeries,
  resource,
  type,
  ignoreZero = false,
  norm = false
) => {
  let series = [];

  // Add trend series
  if (!norm && trends && trends.length > 0) {
    if (ignoreZero) {
      const nonZeroTrends = [];
      if (trends && trends.length > 0) {
        trends.forEach((t) => {
          if (sum(t.data) > 0) {
            nonZeroTrends.push(t);
          }
        });
        series.push(...nonZeroTrends);
      } else {
        series.push(...trends);
      }
    } else {
      series.push(...trends);
    }
  }

  // Go through the graphData and only add non-empty years/series
  if (ignoreZero) {
    let nonZeroSeries = [];
    if (graphData && graphData.length > 0) {
      graphData.forEach((g) => {
        if (sum(g.data) > 0) {
          nonZeroSeries.push(g);
        }
      });
      series.push(...nonZeroSeries);
    } else {
      series.push(...graphData);
    }
  } else {
    series.push(...graphData);
  }

  // If heating by load, calculate sum of previous series and separate ahu data from that
  if (resource === "heating" && type === "load" && !norm) {
    let ahuSeries = [];
    ahuSeries = mapHeatingByLoad([...series], ahuData);
    series = [...ahuSeries];
  }
  // If electricity by load, calculate sum of previous series and separate HP data from that
  if (resource === "electricity" && type === "load" && !norm) {
    let hpSeries = [];
    hpSeries = mapElectricityByLoad([...series], hpData);
    series = [...hpSeries];
  }

  // Set year series
  let yearSeries = [];
  yearSeries = createYearGroups({ data: [...series], type: resource });
  if (yearSeries && yearSeries.length > 0) {
    series.push(...yearSeries);
  }
  // Set visible years
  let visibleSeries = [];
  visibleSeries = [...setVisibleYears([...series])];

  // Check if targetData exists
  if (targetData) {
    visibleSeries.push(targetData);
  }
  // Check chart y-axis
  let yAxis = [
    {
      // Primary axis
      min: 0,
      title: {
        text: setConsumptionUnitText(resource),
      },
      stackLabels: {
        enabled: true,
        allowOverlap: true,
        formatter: function () {
          //heating by source is a stacked column which has greater length than others. If not, don´t show total.
          if (this.axis.series.length <= 15) return;
          var val = this.total;
          if (val > 0) {
            return val;
          }
          return "";
        },
      },
    },
  ];

  // Check if temperatureData exists
  if (temperatureSeries && temperatureSeries.length > 0) {
    temperatureSeries.map((s) => visibleSeries.push(s));
    // Also create secondary y-axis for outside temperature
    yAxis.push({
      min: -30,
      max: 30,
      title: {
        text: "Temperature (°C)",
      },
      opposite: true,
    });
  }
  let finalSeries = [];
  finalSeries = visibleSeries; //[...setStackColor(visibleSeries)];

  // Choose the proper title
  let title = "";
  if (resource === "heating") {
    if (type === "source") {
      title = "Heating by source";
    } else if (type === "load") {
      title = "Heating by load";
    }
    if (norm) {
      title = "Total heating - weather corrected";
    }
  } else if (resource === "electricity") {
    if (type === "source") {
      title = "Electricity by source";
    } else if (type === "load") {
      title = "Electricity by load";
    }
  } else if (resource === "cooling") {
    if (type === "source") {
      title = "Cooling by source";
    } else if (type === "load") {
      title = "Cooling by load";
    }
  }

  return {
    chart: {
      type: "column",
    },
    title: {
      text: title,
    },
    series: finalSeries,
    yAxis: yAxis,
    plotOptions: {
      column: {
        stacking: "normal",
      },
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    tooltip: {
      shared: true,
      valueDecimals: 2,
      outside: true,
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      crosshair: true,
    },
  };
};

export default graphOptions;
