import { raConsumptionToHighchartsData } from "../../../utils/raDataToHighcharts";
import graphColor from "../../../utils/graphColor";
import handleLegendClick from "./handleLegendClick";

const seriesMapping = (data, type, value, allCons) => {
  // Get unique years from consumption data.
  const years = [
    ...new Set(
      data.map((obj) => {
        const date = new Date(obj.Time);
        return date.getFullYear();
      })
    ),
  ];
  // create an array of series, one for each year.

  const series = years.map((year, index) => {
    return {
      name: year,
      stack: year,
      data: raConsumptionToHighchartsData(data, type, value, year, allCons),
      visible: true,
      color: graphColor({ index, seriesLength: years.length, type }),
      events: {
        legendItemClick: (event) => {
          event.preventDefault();
          handleLegendClick(event.target);
          return false;
        },
      },
    };
  });
  return series;
};
export default seriesMapping;
