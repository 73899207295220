import React from "react";
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  Pagination,
} from "react-admin";
//import { makeStyles } from "@material-ui/core";

import { stateMapper } from "../customReducers/connectFunctions";
//import { setConsumptionUnitText } from "../utils/unitTextParsers";
import PageTitle from "../components/layout/PageTitle";
import useUserRole from "../utils/hooks/useUserRole";
import { isNil } from "lodash";

/*const useStyles = makeStyles(() => ({
  helperText: {
    "& .MuiFormHelperText-contained": {
      display: "none",
    },
  },
}));*/

const ValuesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
);

const EnergyTypeList = ({ BuildingId, projectname, ...props }) => {
  //const { isAdminOrUser } = useUserRole();
  // Prevent rendering component without BuildingId
  if (isNil(BuildingId)) {
    return null;
  }
  // Delete unused/unnecessary props
  let listProps = Object.assign({}, props);
  delete listProps.buildingTwinId;
  delete listProps.buildingSelected;
  delete listProps.dispatch;

  return (
    <>
      <PageTitle title="Consumptions By Energy Type" />
      <List
        {...listProps}
        perPage={25}
        pagination={<ValuesPagination />}
        filterDefaultValues={{ BuildingId }}
        bulkActionButtons={false}
        onError={(e) => console.warn("HELLO", e)}
      >
        <Datagrid>
          <DateField
            source="Time"
            label="Date"
            options={{ year: "numeric", month: "numeric" }}
          />
          <NumberField source="Oil" options={{ useGrouping: false }} />
          <NumberField
            source="DistrictHeating"
            options={{ useGrouping: false }}
          />
          <NumberField source="NaturalGas" options={{ useGrouping: false }} />
          <NumberField
            source="LiquidPetroleum"
            options={{ useGrouping: false }}
          />
          <NumberField source="Steam" options={{ useGrouping: false }} />
          <NumberField
            source="CondensateHeat"
            options={{ useGrouping: false }}
          />
        </Datagrid>
      </List>
    </>
  );
};

export default stateMapper(EnergyTypeList);
